import React from 'react';
import styles from './UserExperience.module.scss';
import { RichText } from 'prismic-reactjs';

const UserExperience = ({ primary, items }) => {
    // console.log('items', items, primary);
    const { title } = primary;
    return (
        <div className={styles.newpageHero}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <RichText render={title.richText} />
                    <div className={styles.iconslist}>
                        {items?.map((item) => {
                            return (
                                <div className={styles.icon} key={item.icon}>
                                    <img src={item?.usericon
                                        ?.url} alt={item?.usericon
                                            ?.alt} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserExperience
