import React, { useState, useContext } from 'react';
import { object } from 'prop-types';

import { dateToString, parseString, langPath } from '@helpers';
import LangContext from '@contexts';
import Image from '@components/Image/Image';
// import style from './ArticlePreview.module.scss';
// import Arrow from './image/arrow.inline.svg';
import { Link } from 'gatsby';
import Button, { VARIANT } from '@components/Button/Button.js';
import styles from "./NewRelatedBlog.module.scss";
import { RichText } from 'prismic-reactjs';
import Arrow from './image/arrow.inline.svg';

const NewRelatedBlog = ({ node }) => {
    // const { data: postData, tags, uid: postPath } = node;
    // const { title, description, date, preview } = postData;
    // const currentLang = useContext(LangContext);
    // const link = `${langPath(currentLang)}/blog/${postPath}`;

    const { data: postData, tags, uid: postPath } = node;
    const { title, description, date, preview } = postData;
    const currentLang = useContext(LangContext);
    const link = `${langPath(currentLang)}/blog/${postPath}`;

    // const { blogimg, description, title, tag, date } = items;
    // console.log("relatedBlog", items)
    // console.log("relatedBlog", primary)

    // const handleClickLoadMore = () => {
    //     setCounter(counter + 3);
    // };
    // console.log(counter, "counter")

    return (
        // <Link to={""} className={"style.preview"}>
        <section className={styles.relatedBlog}>
            <>
                <Link to={link} className={styles.preview}>
                    <div className={styles.imagePreview}>
                        {preview ? (
                            <Image image={preview} />
                        ) : (
                            <div className={styles.placeholder}></div>
                        )}
                    </div>
                    <div className={styles.textBlock}>
                        <h3 className={styles.title}>{parseString(title.richText)}</h3>
                        <p className={styles.text}>{parseString(description.richText)}</p>
                        <div className={styles.wrapper}>
                            <ul className={styles.categoryList}>
                                {tags.map((item, index) => {
                                    return (
                                        <li className={styles.tag} key={`${item}${index}`}>
                                            <span>{item}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                            <time className={styles.date}>{dateToString(date)}</time>
                        </div>
                        <div className={styles.arrow}>
                            <Arrow />
                        </div>
                    </div>
                </Link>
            </>
            {/* </div> */}
        </section>
        // </Link>
    );
};

NewRelatedBlog.propTypes = {
    node: object,
};

export default NewRelatedBlog;
