import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

// import SolutionPage from '../scenes/SolutionPage';
import Layout from '@components/Layout';
import FrontPage from '../scenes/NewFrontPage';

const Page = ({ data }) => {
  const pageContext = data?.allPrismicNewFrontPage?.edges[0];
  // console.log('pageContext', pageContext);
  if (!pageContext) return null;
  const body = pageContext.node;
  const { data: pageData, uid, id, lang, type, alternate_languages } = body;
  const { metatitle, metadescription, canonical, body: pageContent } = pageData;
  const activeDocMeta = { id, uid, lang, type, alternate_languages };

  // blogsData
  const blogData = data?.allPrismicBlogpostpage?.edges;

  // const mainSection = data.allPrismicLayout.edges;

  // console.log('uid', uid);
  // console.log(data, "data into allthedata")

  return (
    <Layout
      activeDocMeta={activeDocMeta}
      metatitle={metatitle}
      metadescription={metadescription}
      canonical={canonical}
    >
      <FrontPage
        current={pageContent}
        pageUid={uid}
        canonical={canonical}
        metatitle={metatitle}
        lang={lang}
        blogData={blogData}
      />
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query($lang: String) {
    allPrismicNewFrontPage {
      edges {
        node {
          type
          lang
          id
          alternate_languages {
            id
            lang
            uid
            type
          }
          data {
            body {
              ... on PrismicNewFrontPageDataBodyUserexperience {
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                }
                items {
                  usericon {
                    url
                    alt
                  }
                }
              }
              ... on PrismicNewFrontPageDataBodyBannerdata {
                id
                slice_type
                items {
                  bannerimg {
                    alt
                    url
                  }
                }
                primary {
                  buttontext {
                    richText
                  }
                  buttonlink {
                    url
                  }
                  bannerimg {
                    alt
                    url
                  }
                  bannertitle {
                    richText
                  }
                }
              }
              ... on PrismicNewFrontPageDataBodyGlobaltemplate {
                id
                slice_type
                items {
                  icon_image {
                    url
                    alt
                  }
                  image {
                    url
                    alt
                  }
                  text {
                    richText
                  }
                  title {
                    richText
                  }
                  lawlink {
                    richText
                  }
                }
                primary {
                  description {
                    richText
                  }
                  title {
                    richText
                  }
                }
              }
              ... on PrismicNewFrontPageDataBodyPrivacymanagement {
                id
                slice_type
                items {
                  img {
                    url
                  }
                  pagename {
                    richText
                  }
                }
                primary {
                  buttonlink {
                    url
                  }
                  buttontext {
                    richText
                  }
                  description {
                    richText
                  }
                  title {
                    richText
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicBlogpostpage(
      filter: { lang: { eq: $lang } }
      limit: 1000
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          uid
          type
          lang
          id
          alternate_languages {
            id
            lang
            type
            uid
          }
          tags
          data {
            backgroundpreview {
              alt
              url
            }
            date
            description {
              richText
            }
            title {
              richText
            }
            preview {
              alt
              url
            }
            body {
              ... on PrismicBlogpostpageDataBodyAgencies {
                id
                slice_type
                primary {
                  buttonlink {
                    richText
                  }
                  buttontext {
                    richText
                  }
                  description {
                    richText
                  }
                  image {
                    alt
                    url
                  }
                  sectiontitle {
                    richText
                  }
                }
              }
              ... on PrismicBlogpostpageDataBodyBlogDetailsPageCtaButton {
                id
                primary {
                  cta_options
                  blog_page_cta_button_link {
                    url
                  }
                  blog_page_cta_button_text {
                    richText
                  }
                  cta_header_title {
                    richText
                  }
                  cta_header_description {
                    richText
                  }
                  blog_page_cta_button_text {
                    richText
                  }
                  blog_page_cta_button_link {
                    url
                  }
                  logo {
                    url
                    alt
                  }
                }
                slice_type
              }
              ... on PrismicBlogpostpageDataBodyArticles {
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                  buttontext {
                    richText
                  }
                }
              }
              ... on PrismicBlogpostpageDataBodyCodeSnippet {
                id
                slice_type
                primary {
                  quote {
                    richText
                  }
                }
              }
              ... on PrismicBlogpostpageDataBodyCentralizedCtaFromBlogSingle {
                id
                slice_type
              }
              ... on PrismicBlogpostpageDataBodyImage {
                id
                slice_type
                primary {
                  caption {
                    richText
                  }
                  image {
                    alt
                    url
                  }
                }
              }
              ... on PrismicBlogpostpageDataBodyQuote {
                id
                slice_type
                primary {
                  quote {
                    richText
                  }
                }
              }
              ... on PrismicBlogpostpageDataBodySubscribe {
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                  buttontext {
                    richText
                  }
                  placeholder {
                    text
                  }
                }
              }
              ... on PrismicBlogpostpageDataBodyTable {
                id
                slice_type
                items {
                  col1 {
                    richText
                  }
                  col2 {
                    richText
                  }
                  col3 {
                    richText
                  }
                  col4 {
                    richText
                  }
                }
              }
              ... on PrismicBlogpostpageDataBodyTableRowsHeaders {
                id
                slice_type
                items {
                  col1 {
                    richText
                  }
                  col2 {
                    richText
                  }
                  col3 {
                    richText
                  }
                  col4 {
                    richText
                  }
                }
              }
              ... on PrismicBlogpostpageDataBodyTwitterEmbedPost {
                id
                slice_type
                items {
                  twitter_post
                }
              }
              ... on PrismicBlogpostpageDataBodyText {
                id
                slice_type
                primary {
                  text {
                    richText
                  }
                }
              }
              ... on PrismicBlogpostpageDataBodyVideo {
                id
                slice_type
                primary {
                  video {
                    link_type
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Page;
