import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import style from './SolutionPage.module.scss';
import style from './NewFront.module.scss';

import 'swiper/swiper.scss';
// import NewExperience from './components/UserExperience';
import BannerData from './components/BannerData';
import UserExperience from './components/UserExperience';
import NewExperience from './components/NewExperience';
import NewBannerData from './components/NewBannerData';
import GlobalTemp from './components/GlobalTemp';
import PrivacyMent from './components/PrivacyMent/PrivacyMent';
import NewRelatedBlog from './components/NewRelatedBlog/NewRelatedBlog';

// import Hero from '@components/Hero';
// import Projects from './components/Projects/Projects';
// import Benefits from './components/Benefits/Benefits';
// import Features from './components/Features/Features';
// import Questions from '@components/Questions/Questions';
// import Agencies from '@components/Agencies';
// import Plans from '@components/Plans';
// import Calendly from '@components/Calendly/Calendly';
// import FaqSemanticMarkup from '@components/FaqSemanticMarkup/FaqSemanticMarkup';
// import BreadcrumbsSemanticMarkup from '@components/BreadcrumbsMarkup/BreadcrumbsMarkup';
// import Content from '@components/Content/Content';

const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const FrontPage = ({
    current: body,
    pageUid,
    canonical,
    metatitle,
    lang: pageLang,
    blogData
}) => {

    const [visibleItems, setVisibleItems] = useState(3);
    // console.log(body, "body into fontpage");
    // console.log(blogData, "blogData into fontpage");

    const handleClickLoadMore = () => {
        setVisibleItems(prevVisibleItems => prevVisibleItems + 3);
    };

    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

    const filteredBlogData = blogData?.filter(item => new Date(item?.node?.data?.date) >= threeMonthsAgo);
    // console.log(filteredBlogData, "filteredBlogData")

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, []);

    return (
        <div className={style.FrontPage}>
            <div>
                {body.map((section, index) => {
                    switch (section.slice_type) {
                        case 'userexperience':
                            return (
                                <UserExperience
                                    {...section}
                                    key={`${section.slice_type}${index}`}
                                // handleMoveToWhatYouNeed={handleMoveToWhatYouNeed}
                                />
                            );
                        case "bannerdata":
                            return (
                                <BannerData {...section} key={`${section.slice_type}${index}`} />
                            );
                        // case "newexperience":
                        //     return (
                        //         <NewExperience
                        //             {...section}
                        //             key={`${section.slice_type}${index}`}
                        //         />
                        //     );
                        // case "newbannerdata":
                        //     return (
                        //         <NewBannerData {...section} key={`${section.slice_type}${index}`} />
                        //     );
                        case "globaltemplate":
                            return (
                                <GlobalTemp
                                    {...section}
                                    key={`${section.slice_type}${index}`}
                                />
                            );
                        case "privacymanagement":
                            return (
                                <div className={style.globalcontainer}>
                                    <PrivacyMent
                                        {...section}
                                        key={`${section.slice_type}${index}`}
                                    />
                                </div>
                            )
                        // case "relatedblog":
                        //     return (
                        //         <NewRelatedBlog
                        //             {...section}
                        //             key={`${section.slice_type}${index}`}
                        //         />
                        //     );
                        default:
                            throw new Error(`Unknown section type: ${section.slice_type}`);
                    }
                })}
            </div>
            <div className={style.blogContainer}>
                <div className={style.title}>
                    <h2>Latest</h2>
                    <strong>Blog Posts</strong>
                </div>
                <div className={style.list}>
                    {filteredBlogData?.slice(0, visibleItems)?.map((item) => {
                        return <NewRelatedBlog {...item} key={item.node.uid} />
                    })}
                </div>

                <div className={style.buttonWrapper}>
                    {filteredBlogData?.length >= 3 && visibleItems && (
                        <button className={style.viewArticleButton} onClick={handleClickLoadMore}>
                            Show last 3 months
                        </button>
                    )}
                </div>
            </div>
            {/* <FaqSemanticMarkup questions={generalFaqList} />
            <BreadcrumbsSemanticMarkup
                pageTitle={metatitle.text}
                pageUrl={canonical.text}
            /> */}
        </div>
    );
};

FrontPage.propTypes = {
    current: PropTypes.array.isRequired,
    pageUid: PropTypes.string,
    canonical: PropTypes.object.isRequired,
    metatitle: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};

export default FrontPage;
