import React, { useEffect, useState } from 'react'

import style from './Bannerdata.module.scss'
import { RichText } from 'prismic-reactjs';
import Swiper from 'react-id-swiper';
import Image from '@components/Image/Image';

import 'swiper/css/swiper.css';
import ArrowNewButtton from '../ArrowNewButton/ArrowNewButtton';
import Button, { VARIANT } from '@components/Button/Button.js';
import { navigate } from 'gatsby';
import Prev from "./mainfile/prevbutton.js"
import Next from "./mainfile/Icon.js"
// import Swiper from 'swiper';
// import { EffectFade, Navigation, Pagination } from "swiper";

const BannerData = ({ primary, items }) => {
    // console.log('items', items);
    const { bannertitle, buttonlink, buttontext } = primary;
    const [swiper, setSwiper] = useState(null);
    const [index, setIndex] = useState(0);

    // console.log(bannertitle, 'bannertitle');


    useEffect(() => {
        if (swiper) {
            swiper.update();
        }
    }, [swiper]);

    const goNext = () => {
        if (swiper) {
            swiper.slideNext();
            setIndex(index + 1)
        }
    };

    const goPrev = () => {
        if (swiper) {
            swiper.slidePrev();
            setIndex(index - 1);
        }
    };



    // const handleClick = (e, buttonLink) => {
    //     e.preventDefault();
    //     trackVisitedSignUpButtonClick('Visited Sign-Up Page');
    //     navigate(`${buttonlink.url}`);
    // };
    return (
        <div className={style.container}>
            <div className={style.content}>
                <div className={style.newtitle}>
                    <span className={style.swiperPrev} onClick={goPrev}><Prev /></span>
                    <span className={style.swiperNext} onClick={goNext}><Next /></span>
                    <div className={style.title}>
                        <RichText render={bannertitle.richText} />
                        <div className={style.banneritem}>
                            <div className={style.bannerimgData}>
                                {/* <img src={bannerimg?.url} alt={bannerimg?.alt} className={style.mainImg} /> */}
                                <div className={style.itemsData}>
                                    <Swiper getSwiper={setSwiper} {...params}>
                                        {items?.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className={`${style.icon} ${style[`icon${index + 1}`]}`} >
                                                        <img src={item?.bannerimg?.url} alt={item?.bannerimg?.alt} />
                                                        {/* <Image image={item?.bannerimg} alt={item?.bannerimg?.alt} /> */}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={style.buttonWrapper}>
                <Button
                    variant={VARIANT.PRIMARY}
                    isHeader={true}
                    to={`https://cmp.secureprivacy.ai/onboarding?bannerName=${items[index].bannerimg.alt}`}
                // to={`${buttonlink.url}`}
                // click={(e) => handleClick(e, buttonlink)}
                >
                    <RichText render={buttontext.richText} />
                </Button>
            </div>
        </div>
    )
}

const params = {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    breakpoints: {
        768: {
            slidesPerView: 1,
            spaceBetween: 0,
        },
    },
    // navigation: {
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev',
    //     // enabled: true,
    // },
    // renderPrevButton() {
    //     return <ArrowNewButtton type="prev" />;
    // },
    // renderNextButton() {
    //     return <ArrowNewButtton type="next" />;
    // },
    effect: 'fade',
    fadeEffect: {
        crossFade: true,
        // direction: 'vertical',
    },
    // modules: [EffectFade, Navigation, Pagination]
};

export default BannerData