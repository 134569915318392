import React, { useContext } from 'react'
import styles from "./PrivacyMent.module.scss";
import { RichText } from 'prismic-reactjs';
import Button, { VARIANT } from '@components/Button/Button.js';
import { Link } from 'gatsby';
import { langPath } from '@helpers';
import LangContext from '@contexts';


const PrivacyMent = ({ primary, items }) => {
    // console.log('items', items, primary)
    const { title, description, buttonlink, buttontext } = primary
    const currentLang = useContext(LangContext);
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <RichText render={title.richText} />
            </div>
            <div className={styles.descr}>
                <RichText render={description.richText} />
            </div>
            <div className={styles.terms}>
                {items?.map(({ img, pagename }, index) => {
                    const link = `${langPath(currentLang)}/${RichText.asText(
                        pagename.richText
                    )}`;
                    // console.log("link into page", link)
                    return (
                        <Link to={link} className={styles.newLink}>
                            <div className={styles.termsItem} key={""}>
                                <img src={img?.url} alt={img?.alt} />
                            </div>
                        </Link>
                    )
                })}
            </div>
            <div className={styles.buttonWrapper}>
                <Button variant={VARIANT.SECONDARY}>
                    <RichText render={buttontext.richText} />
                </Button>
            </div>
        </div>
    )
}

export default PrivacyMent
